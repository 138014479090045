import React from 'react';
import './Spinner.css';

const Spinner = () => (
    <div className="loader">
        Loading...
    </div>
);

export default Spinner;


